
  import Vue from "vue";
  import {MODAL_IDS, MODALS_LOADED} from "@/utils/modal";
  import {IMAGES_SEGMENTS} from "@/utils/images";

  export default Vue.extend({
    name: "pwa-user-promotion-modal",
    data() {
      return {
        loadContent: false,
        imagesSegments: IMAGES_SEGMENTS,
      };
    },
    mounted(): void {
      MODALS_LOADED[this.modalId] = true;
    },
    computed: {
      modalId(): string {
        return MODAL_IDS.USER_PROMOTION;
      },
      userPromotion(): {id: number, title: string, text: string, button_text: string ,img: string, url: string} {
        return this.$store.getters.modalProps.userPromotion || {};
      },
    },
    methods: {
      onShow() {
        this.loadContent = true;
        if (this.userPromotion && this.userPromotion.id) {
          this.$http.promotions.postPromotionRead(this.userPromotion.id);
        }
      },
      onHidden() {
        this.loadContent = false;
      },
      closeModal() {
        this.hideModalById(this.modalId);
      },
      goToPromotion() {
        if (this.userPromotion.url) {
          if (this.userPromotion.url.charAt(0) === '/') {
            this.routerPathPush(this.userPromotion.url);
          } else {
            this.openLink(this.userPromotion.url);
          }
        }
        this.closeModal();
      },
    }
  });
